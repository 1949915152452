import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAk-dPO-SQiLNUL1f0DFYH0L1LnLfRT9g0",
  authDomain: "metaluxe-web-app-86723.firebaseapp.com",
  projectId: "metaluxe-web-app-86723",
  storageBucket: "metaluxe-web-app-86723.appspot.com",
  messagingSenderId: "877068280713",
  appId: "1:877068280713:web:4debca8b5a519f2692b758",
  measurementId: "G-H51M1MM5ZP"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

export { auth, database }
