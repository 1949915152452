<template>
  <AssetTypeVideo />
  <div class="container h-100 d-flex align-items-center justify-content-center z-2">
    <div class="row mobile align-content-between g-0">
      <TypeTopNav :heading="this.assetType" />

      <div class="col-12 d-flex align-items-center justify-content-center z-2">
        <div class="row g-0">
          <div class="col-12" v-if="collection">
            <template v-for="asset in assetTypes" :key="asset.Guid">
              <!-- Video links -->
              <router-link v-if="asset.LinkName === 'video' || asset.LinkName == 'videos'" :to="{
        name: asset.ComponentName,
        params: {
          assetType: this.assetType,
          viewType: asset.LinkName,
          lid: collection.videoLibraryId,
          collectionId: collection.guid
        }
      }" class="btn-theme-menu mb-2">
                <img :src="theme.videosIcon" :alt="asset.ObjectName" class="me-3">{{ asset.ObjectName }}
              </router-link>
              <!-- Panorama links -->
              <router-link
                v-if="asset.LinkName === 'panoramas' || asset.LinkName === 'virtual-tour' || asset.LinkName === 'balcony-views'"
                :to="{
        name: asset.ComponentName,
        params: {
          assetType: this.assetType,
          viewType: asset.LinkName
        }
      }" class="btn-theme-menu mb-2">
                <img :src="theme.panoramasIcon" :alt="asset.ObjectName" class="me-3">{{ asset.ObjectName }}
              </router-link>

              <!-- Renders links -->
              <router-link v-if="asset.LinkName === 'renders' || asset.LinkName === 'range'" :to="{
        name: asset.ComponentName,
        params: {
          assetType: this.assetType,
          viewType: asset.LinkName
        }
      }" class="btn-theme-menu mb-2">
                <img :src="theme.rendersIcon" :alt="asset.ObjectName" class="me-3">{{ asset.ObjectName }}
              </router-link>

              <!-- Floorplans links -->
              <router-link v-if="asset.LinkName === 'floorplan'" :to="{
        name: 'AssetViewDetail',
        params: {
          assetType: this.assetType,
          viewType: 'floorplan',
          order: 0
        }
      }" class="btn-theme-menu mb-2">
                <img :src="theme.floorplansIcon" :alt="asset.ObjectName" class="me-3">{{ asset.ObjectName }}
              </router-link>

              <!-- PDF links -->
              <router-link v-if="asset.LinkName === 'pdf'" :to="{
        name: asset.ComponentName,
        params: {
          assetType: this.assetType,
          viewType: asset.LinkName
        }
      }" class="btn-theme-menu mb-2">
                <img :src="theme.floorplansIcon" :alt="asset.ObjectName" class="me-3">{{ asset.ObjectName }}
              </router-link>
            </template>

          </div>
        </div>
      </div>

      <TypeBotNav />
    </div>
  </div>
  <OffCanvas />
</template>

<script>
import AssetTypeVideo from '@/components/AssetTypeVideo.vue'
import TypeTopNav from '@/components/TypeTopNav.vue'
import TypeBotNav from '@/components/TypeBotNav.vue'
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'

export default {
  name: "AssetType",
  props: {
    assetType: String
  },
  components: {
    AssetTypeVideo,
    TypeTopNav,
    TypeBotNav,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      collection: null,
      componentName: "AssetView",
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/'),
      assetOrder: null
    }
  },
  created() {
    BunnyNetService.getFolders(this.folderStructure)
      .then(response => {
        // Remap array
        console.log(response)
        const menuItems = response.data.map((item) => {
          return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            ComponentName: this.componentName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName
          };
        });


        // Sort array
        const customMenu = sortArray(menuItems, {
          by: ['LinkName'],
          order: 'LinkName',
          customOrders: {
            LinkName: this.assetOrder
          }
        })

        this.assetTypes = customMenu
      })
      .catch(error => {
        console.log(error)
      }),
      // api call get video collection details
      BunnyNetService.getCollections(this.theme.videoLibraryId, this.assetType)
        .then(response => {

          this.collection = response.data[0]
        })
        .catch(error => {
          console.log(error)
        })
  },
  setup() {
    // load global config
    const { theme } = global

    return {
      theme
    }
  }
}
</script>
