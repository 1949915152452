import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",

    streamToken: "32928aba-f10c-4a02-88d3-9c56988cfb82",
    videoLibraryId: "269303",
    streamCdnUrl: "https://vz-7135b5f1-228.b-cdn.net",
    streamApiKey: "1dc53bbb-9b5b-4c14-ad052e5028b0-997a-4cee",

    storageApiPassword: "34abf08e-2ebd-47ef-a52c6aa6005f-f197-4a60",
    cdnUrl: "https://avidvantage.b-cdn.net",
    devFolder: "/avidvantage",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    dashboardMp4: "theme/dashboard/4K_dashboard.mp4",

    // top level menu
    assetsMenuOrder: [
        'master-plan-flythrough',
        'balcony-views',
        'apartment-f2',
        'apartment-b2',
        'apartment-a10',
        'apartment-c5',
    ],

    bed1MenuOrder: [
        'videos',
        'virtual-tour',
        'balcony-view',
        'floorplan',
        'range',
        'renders',
        'pdf'
    ],


});

export default { theme };
